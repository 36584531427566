import { Box, Container, Typography, styled } from "@mui/material";

export const ContactContainer = styled(Container)`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
background-color: #993C4E;
overflow: hidden;
`;

export const ContactIntro = styled(Typography)`
font-family: 'Raleway', sans-serif;
font-weight: 600;
`;

export const ContactBox = styled(Box)`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
text-align: center;
`;

export const ContactText = styled(Typography)`
font-family: 'Raleway', sans-serif;
font-weight: 600;
color: #000;
line-height: 2.3;
`;

