import React from 'react'
import { ContactContainer, ContactIntro, ContactBox, ContactText } from './ContactElement'
import { theme } from '../Header'
import { ThemeProvider } from '@mui/material/styles';

function Contact() {
  return (
  <ContactContainer id='contact' maxWidth>
    
    <ContactIntro variant='h2'>Contact Us</ContactIntro>
        <ContactBox>
        <ThemeProvider theme={theme}>
        <ContactText variant={ window.innerWidth < 490 ? 'h3' : 'h2' }>
        Forwarding Address: 4719 Quail Lakes Dr Ste G #1236 Stockton, CA 95207< br />
        Phone: (251) 333-1596 <br />
        Fax: (209) 290-3672 <br />
        Email: Customerservice@holeoutdonuts.com
        </ContactText>
        </ThemeProvider>

        </ContactBox>
        <ContactText sx={{ fontSize: '12px', color: '#fff' }}>
        &copy; 2023 Holeout Donuts. All Rights Reserved.
        </ContactText>

    
  </ContactContainer>
  )
}

export default Contact