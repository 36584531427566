import { Container, Typography, styled, css } from "@mui/material";
import { Link } from 'react-scroll'

export const HeaderContainer = styled(Container)`
display: flex;
justify-content: center;
align-items: center;
gap: 3rem;
padding: 1rem;
overflow: hidden;
height: 120px;

@media screen and (max-width: 480px) {
  gap: 1.3rem;
}

`;

export const Spin = styled('span')`


${({ isActive }) => isActive &&
css`

  content: url(https://img.icons8.com/external-solid-design-circle/64/86ba7d/external-Donut-cafe-solid-design-circle.png);
  position: absolute;
  width: 35px;
  height: 35px;
  bottom: -70px;
  border-radius: 50%;
  transform: rotate(360deg);
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  transition: all 0.5s ease-in-out;
  `}

`;

export const HoleOutNav = styled('nav')`
position: sticky;
top: 0;
height: 0px;
width: 100%;
background-color: transparent;
z-index: 100;
`;

export const HoleOutLogo = styled('img')`
width: 50px;
height: 50px;
cursor: pointer;
border-radius: 50%;
background-color: black;

&:active {
    transform: translateY(1px);
}
`;

export const HoleOutLink = styled(Link)`
position: relative;
cursor: pointer;
`;

export const HoleOutLinkText = styled(Typography)`
position: relative;
font-family: 'Raleway', sans-serif;
font-weight: 300;
color: #fff;
width: 100%;
    
`;
