import { Box, Container, Typography, styled } from "@mui/material";
import DonutSpin from '../../Images/DonutSpin.svg';


export const HomeContainer = styled(Container)`
display: flex;
height: 100%;
flex-direction: column;
justify-content: center;
align-items: center;
overflow: hidden;
position: relative;
padding: 1rem;
background-color: #000;
`;

export const HomeBackground = styled('img')`
    object-fit: cover;
    width: 100%;
    height: 100%;
    top: 50%; 
    left: 50%; 
    transform: translate(-50%, -50%);
    position: absolute;
`; 

export const Holeout = styled(Typography)`
position: relative;
top: 26rem;
font-family: 'Raleway', sans-serif;
font-weight: 900;
text-align: center;
line-height: 1.3;
color:  #556B2F;
text-transform: uppercase;
z-index: 12;
animation: myAnim 2s ease 0s 1 normal forwards;

@media screen  and (max-width: 480px) {
  top: 18rem;
  
}
  `;

export const RollContainer = styled(Box)`
display: flex;
justify-content: center;
align-items: center;
height: 700px;
z-index: 10;

@media screen and (max-width: 480px) {
    height: 450px;
}

@media screen  and (max-width: 320px) {
    height: 335px;
}
`;




export const DonutRoll = styled(Box)`
display: flex;
justify-content: center;
align-items: center;
text-align: justify;
background: url(${DonutSpin});
background-size: 740px 740px;
background-repeat: no-repeat;
background-position: center;
border-radius: 50%;
height: 640px;
width: 640px;
overflow: hidden;
animation: spin 5s cubic-bezier(0.4, 2.5, 0.3, 0.9) forwards;
 
@keyframes spin {
    0% {
      transform: translateX(-900px) rotate(0deg);
    }

    100% {
      transform: translateX(0) rotate(360deg);
  }

} 

@media screen and (max-width: 768px) {
  background-size: 550px 550px;
  height: 480px;
  width:480px;
  
}

@media screen and (max-width: 480px) {
  background-size: 390px 390px;
  height: 335px;
  width: 335px;
}

@media screen  and (max-width: 360px) {
  background-size: 290px 290px;
  height: 250px;
  width: 250px;
}
  
`; 

export const DonutIntro = styled(Box)`
display: flex;
justify-content: center;
align-items: center;
padding: 1rem;
background-color: rgb(255, 255, 255);
border-radius: 10px;
width: 50%;

@media screen and (max-width: 1090px) {

  width: 95%;
}

@keyframes myAnim {
	0% {
		transform: translateY(700px) scale(0.5);
	}

	100% {
		transform: translateY(0);
	}
}
animation: myAnim 2s ease 0s 1 normal forwards;
`;

export const DonutText = styled(Typography)`
font-family: 'Raleway', sans-serif;
font-weight: 600;
color: #000;
line-height: 1.3;
text-transform: uppercase;
text-align: center;
animation: myAnim 2s ease 0s 1 normal forwards;


@media screen and (max-width: 768px) {
  width: 95%;
}
`;



