import React, {useState} from 'react'
import { HeaderContainer, HoleOutNav, HoleOutLink, HoleOutLinkText, HoleOutLogo, Spin } from './HeaderElement'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import HoleLogo from '../../Images/HoleOutLogo.svg';
export const theme = createTheme({
  typography: {
    h1: {
      fontSize: {
        xs: 36,
        sm: 48,
        md: 64,
      },
    },
    h2: {
      fontSize: {
        xs: 32,
        sm: 40,
        md: 56,
      },
    },
    h3: {
      fontSize: {
        xs: 28,
        sm: 36,
        md: 48,
      },
    },
    h4: {
      fontSize: {
        xs: 24,
        sm: 32,
        md: 40,
      },
    },
    h5: {
      fontSize: {
        xs: 20,
        sm: 28,
        md: 36,
      },
    },
    h6: {
      fontSize: {
        xs: 18,
        sm: 24,
        md: 32,
      },
    },
  },
});

function Header() {

  const [activeLink, setActiveLink] = useState(null);

  const handleLinkClick = (path) => {
    if(activeLink === path) {
      setActiveLink(null);
    } else { 
    setActiveLink(path);
    }
  };

  const theme = createTheme({
    typography: {
      h1: {
        fontSize: {
          xs: 36,
          sm: 48,
          md: 64,
        },
      },
      h2: {
        fontSize: {
          xs: 32,
          sm: 40,
          md: 56,
        },
      },
      h3: {
        fontSize: {
          xs: 28,
          sm: 36,
          md: 48,
        },
      },
      h4: {
        fontSize: {
          xs: 24,
          sm: 32,
          md: 40,
        },
      },
      h5: {
        fontSize: {
          xs: 20,
          sm: 28,
          md: 36,
        },
      },
      h6: {
        fontSize: {
          xs: 18,
          sm: 24,
          md: 32,
        },
      },
    },
  });  

const navItems = [
    { label: 'Home', path: 'home' },
    { label: 'About', path: 'about' },
    { label: 'Contact', path: 'contact' }, 
]

const activeLinkStyles = { 
  transition: 'all 0.3s ease-in-out',
  padding: '0.9px',
  transform: 'translateY(-5px) scale(1.1)',
  backgroundColor: '#ff5733',
  borderRadius: '3px',
  width: 'fit-content',
}

  return (
    <>
    <HoleOutNav>
      <ThemeProvider theme={theme}>
        <HeaderContainer maxWidth disableGutters>
          <HoleOutLink
            to="home"
            smooth={true}
            duration={500}
            spy={true}
            exact="true"
            offset={-80}
            onClick={() => handleLinkClick('home')}>
            <HoleOutLogo src={HoleLogo} />
          </HoleOutLink>
          {navItems.map((item, index) => (
            <HoleOutLink
              key={index}
              to={item.path}
              smooth={true}
              duration={500}
              spy={true}
              exact="true"
              offset={10}
              onClick={() => handleLinkClick(item.path)}
              style={activeLink === item.path ? activeLinkStyles: {}}
            >
              <HoleOutLinkText variant="h2">{item.label}</HoleOutLinkText>
              <Spin isActive={activeLink === item.path }></Spin>
            </HoleOutLink>
          ))}
        </HeaderContainer>
      </ThemeProvider>
      </HoleOutNav>
    </>
    
  )
}

export default Header