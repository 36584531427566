import React, { useState } from 'react'
import { HomeContainer, HomeBackground, Holeout, RollContainer, DonutRoll, DonutIntro, DonutText } from './HomeElement'
import bgImage from '../../Images/HoleOutLogo.svg';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from '../Header';



function Home() {
  const [bgLImage, bgImageLoaded] = useState(false);

  const handleBgImageLoad = () => {
    bgImageLoaded(true);
  }
  

const donutIntro = [
  `Welcome to Holeout Donuts, where every bite is a delightful surprise. We craft irresistible, handcrafted donuts that promise to satisfy your sweet cravings. Join us on a mouthwatering journey through our delectable creations and elevate your love for donuts with Holeout!`
]

  return (
   <HomeContainer id='home' maxWidth disableGutters>
    <HomeBackground onLoad={handleBgImageLoad} src={bgImage} alt='HoleOut Logo' />

    {!bgLImage && (
        <div style={{ height: '1000px'}}></div>
      )}

      {bgLImage && (
        <>
     <Holeout variant={window.innerWidth < 650 ? 'h2' : 'h1'}>Holeout Donuts</Holeout>
       
     <RollContainer>
  <DonutRoll boxShadow={5}></DonutRoll>
     </RollContainer>

     <DonutIntro boxShadow={5}>
      <ThemeProvider theme={theme}>
      <DonutText variant='h3'>{donutIntro}</DonutText>
      </ThemeProvider>
     </DonutIntro>
      </>
      )}

   </HomeContainer>
  )
}

export default Home;