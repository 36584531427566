import React, {useRef, useLayoutEffect} from 'react'
import { AboutContainer, Hole, DropDonutRoll, DropRollContainer, AboutIntro, AboutText } from './AboutElement'
import { ThemeProvider } from '@mui/material/styles';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { theme } from '../Header';

function About() { 

  gsap.registerPlugin(ScrollTrigger);
  const dropRoll = useRef(null);
  const spinIn = useRef(null);


useLayoutEffect(() => {

const tl = gsap.timeline();
const tl2 = gsap.timeline();
      
tl.from(dropRoll.current, {
  y: -900,
  ease: "bounce.out", 
  duration: 2,
})

tl2.from(spinIn.current, {
  y: -600,
  rotateX: -30,
  scale: 0,
  transformOrigin: "50% 100%",
  opacity: 0,
  duration: 1,
  ease: 'circ',
})
  .to(spinIn.current, {
    y: 0,
    rotateX: 0,
    scale: 1,
    transformOrigin: "50% 1400px",
    opacity: 1,
  });

      

      const storyLeftTrigger = ScrollTrigger.create({
        trigger: dropRoll.current,
        animation: tl,
        start: '800px center',
        end: '800px bottom',
        toggleActions: 'play none none none',
      });
    
      const teamRightTrigger = ScrollTrigger.create({
        trigger: spinIn.current,
        animation: tl2,
        start: window.innerWidth < 850 ? "-200px center" : "120px center",
        end: window.innerWidth < 850 ? "-200px bottom" : "120px bottom",
        toggleActions: 'play none none none',
      });
    
      return () => {
        storyLeftTrigger.revert(); 
        teamRightTrigger.revert();
      }; 
    }, []);
           

const aboutIntro = [
    `At Hole Out Donuts, we pride ourselves on crafting every donut on-site and made to order, ensuring that each bite is a moment of pure indulgence. Our commitment to freshness sets us apart, offering a unique experience unlike anything in the area.
    What truly sets us apart is the endless array of flavors we offer. From classic cinnamon sugar to adventurous concoctions like matcha-glazed or bourbon-infused, our menu caters to every palate. Whether you're a traditionalist or a flavor explorer, Hole Out Donuts has something to satisfy your cravings.
    Come savor the past and present in every bite, as we redefine the donut experience at Hole Out Donuts.`
]

  return (
    
    <AboutContainer id="about" maxWidth disableGutters>
        
    <Hole variant={window.innerWidth > 480 ? 'h2' : 'h3'}>
      FROM HUMBLE BEGINNINGS TO A{" "}
      <span style={{ color: '#fff', backgroundColor: '#47C038', borderRadius: '10px' }}>"HOLE"</span> LOT OF{" "}
      <span style={{ color: '#fff', backgroundColor: '#DE0734', borderRadius: '10px' }}>LOVE</span>
    </Hole>

   
        <DropRollContainer ref={dropRoll}>
          <DropDonutRoll boxShadow={5}></DropDonutRoll>
        </DropRollContainer>
   
      
        <AboutIntro boxShadow={5} className='slide' ref={spinIn}>
          <ThemeProvider theme={theme}>
            <AboutText variant="h3">{aboutIntro}</AboutText>
          </ThemeProvider>
        </AboutIntro>
   
  </AboutContainer>
    
  )
}

export default About;