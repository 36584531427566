import { styled, Container, Box, Typography } from "@mui/material";
import DropDonutSpin from '../../Images/DonutSpin.svg';

export const AboutContainer = styled(Container)`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
background-color: #A1E44D;
padding: 1rem;
overflow: hidden;
`;

export const Hole = styled(Typography)`
font-family: 'Raleway', sans-serif;
font-weight: 600;
text-align: left;
line-height: 1.3;
`;
    
export const DropRollContainer = styled(Box)`
display: flex;
justify-content: center;
align-items: center;
width: 700px;
height: 700px;
position: relative;
;

@media screen and (max-width: 480px) {
    width: 450px;
    height: 450px;
}

@media screen  and (max-width: 320px) {
        width: 335px;
        height: 335px;
}
       
`;


export const DropDonutRoll = styled(Box)`
display: flex;
justify-content: center;
align-items: center;
text-align: justify;
background: url(${DropDonutSpin});
background-size: 740px 740px;
background-repeat: no-repeat;
background-position: center;
border-radius: 50%;
height: 640px;
width: 640px;
overflow: hidden;
position: relative;

@media screen and (max-width: 768px) {
  background-size: 550px 550px;
  height: 480px;
  width:480px;
  
}

@media screen and (max-width: 480px) {
  background-size: 390px 390px;
  height: 335px;
  width: 335px;
}

@media screen  and (max-width: 360px) {
        background-size: 290px 290px;
        height: 250px;
        width: 250px;
        }
`; 

export const AboutIntro = styled(Box)`
display: flex;
justify-content: center;
align-items: center;
padding: 1rem;
background-color: rgb(0, 0, 0, 1);
border-radius: 10px;
width: 50%;

@media screen and (max-width: 1090px) {
  width: 95%;
}
`;

export const AboutText = styled(Typography)`
font-family: 'Raleway', sans-serif;
font-weight: 600;
color: #fff;
line-height: 1.3;
text-transform: uppercase;
text-align: center;

`;


